.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full-screen height */
}

/* CSS to ensure the dark theme is applied before content appears */
.dark-theme {
  background-color: #121212;
  min-height: 100vh;
}

/* Optional: You can also customize other properties */
.custom-tabs .is-active a {
  --bulma-tabs-link-active-color: #ffffff; /* Custom active link color */
  --bulma-tabs-link-active-border-bottom-color: #fcfcfc; /* Custom active border-bottom color */
  /* --bulma-tabs-border-bottom-width: 2px; */
}

/* .hover:hover {
  color: #a50505; /* Contoh: mengubah warna teks saat hover 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
} */

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: rgb(116, 111, 111) !important;
}

.react-datepicker__day--keyboard-selected {
  color: white !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 0.944rem !important;
  color: rgb(51, 49, 49) !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.select:not(.is-multiple) {
  width: 100%;
}

/* .notification-container {
  position: fixed;
  bottom: 0%;
  left: 39px;
  transform: translatey(-50%);
  z-index: 1000;
  width: 59%;
  max-width: 600px;
}

.notification.is-info {
  text-align: center;
} */

@keyframes toastIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes toastOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

.notification-container {
  position: fixed;
  bottom: 2%; /* Position it at the bottom */
  left: 2%;
  transform: translateX(-50%); /* Center the toast horizontally */
  z-index: 1000;
  width: 27%;
  max-width: 600px;
  animation: toastIn 0.5s ease-out forwards;
}

.notification-container.hide {
  animation: toastOut 0.5s ease-out forwards;
}

.notification.is-info {
  text-align: center;
}

.glow {
  text-shadow: 0 0 5px rgba(255, 0, 0, 0.6);
}

.menu-list li a.is-active {
  background-color: #181b20; /* Ganti dengan warna background yang diinginkan */
  color: white; /* Warna teks saat aktif */
}
